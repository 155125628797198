import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import cubeImage from "../../images/icons/cube/big-cube-3.png"
import MainContentWrap from "../Wrappers/MainContentWrap"

const ServiceHowWeWorkWrap = styled.div`
  .big-cube {
    width: 100%;
    margin-top: 10rem;
  }

  form.how-we-work-btns {
    text-align: left;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
    margin: 0 auto;

    .title,
    .body {
      padding-bottom: 1rem;
    }

    &:hover {
      color: var(--lightShadowColor);
    }

    label {
      cursor: pointer;
      transition: color 0.9s ease 0.1s;

      input {
        display: none;
      }

      &:hover {
        color: var(--darkTextColor);
      }
    }
  }

  @media (min-width: 600px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: none;

    .big-cube {
      margin-top: 0;
      width: 50%;
    }

    .col-wrapper {
      width: 60%;
    }
  }

  @media (min-width: 1024px) {
    .col-wrapper {
      width: 100%;
    }
  }

  .work-item {
    position: relative;
    padding-left: 2.5rem;
  }

  .work-item:before {
    content: "";
    position: absolute;
    top: 0.3rem;
    left: 0;
    width: 5px;
    height: 5px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: var(--darkTextColor);
  }

  .work-item.selected:before {
    background: 0 0;
  }
`

export default function ServiceHowWeWork() {
  const [choosenItem, setChoosenItem] = useState("DISCOVER")

  const handleChange = ({ target: { value } }) => {
    setChoosenItem(value)
  }
  const { t } = useTranslation()

  return (
    <ServiceHowWeWorkWrap>
      <img src={cubeImage} alt="cube image" className="big-cube" />
      <MainContentWrap>
        <div className="row">
          <div className="col-wrapper col-sm-6 col-lg-5">
            <h2 className="fadeup">{t("howWeWorkSecTitle")}</h2>
            <div className="fadeup fadeup-2 sub">
              {t("howWeWorkSecDescription")}
            </div>

            <form className="how-we-work-btns">
              <div
                className={`work-item ${
                  choosenItem === "DISCOVER" && "selected"
                }`}
                data-parent="how-we-work"
              >
                <label className="service-btn">
                  <div className="title">
                    {t("discoverAndDefine")}
                    <input
                      type="radio"
                      value="DISCOVER"
                      checked={"DISCOVER" === choosenItem}
                      onChange={handleChange}
                      className="service-btn-input"
                    />
                  </div>
                  {choosenItem === "DISCOVER" && (
                    <div className="body">
                      {t("discoverAndDefineDescription")}
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`work-item ${
                  choosenItem === "DESIGN" && "selected"
                }`}
                data-parent="how-we-work"
              >
                <label>
                  <div className="title">
                    {t("designAndDevelop")}
                    <input
                      type="radio"
                      value="DESIGN"
                      checked={"DESIGN" === choosenItem}
                      onChange={handleChange}
                      className="service-btn"
                    />
                  </div>
                  {choosenItem === "DESIGN" && (
                    <div className="body">
                      {t("designAndDevelopDescription")}
                    </div>
                  )}
                </label>
              </div>
              <div
                className={`work-item ${
                  choosenItem === "DELIVER" && "selected"
                }`}
                data-parent="how-we-work"
              >
                <label>
                  <div className="title">
                    {t("deliverAndDeploy")}
                    <input
                      type="radio"
                      value="DELIVER"
                      checked={"DELIVER" === choosenItem}
                      onChange={handleChange}
                      className="service-btn"
                    />
                  </div>
                  {choosenItem === "DELIVER" && (
                    <div className="body">
                      {t("deliverAndDeployDescription")}
                    </div>
                  )}
                </label>
              </div>
            </form>
          </div>
        </div>
      </MainContentWrap>
    </ServiceHowWeWorkWrap>
  )
}
