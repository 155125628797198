import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import shortid from "shortid"
import MainContentWrap from "../Wrappers/MainContentWrap"

import iconWebsite from "../../images/icons/cube/cubeS1.jpg"
import iconECommerce from "../../images/icons/cube/cubeS2.jpg"
import iconMobileApp from "../../images/icons/cube/cubeS3.jpg"
import iconBranding from "../../images/icons/cube/cubeS4.jpg"
import iconGraphic from "../../images/icons/cube/cubeS5.jpg"

const ServiceWeOfferWrap = styled.div`
  width: 100%;

  #service-buttons {
    margin: 15rem 0 10rem;
  }

  .service-btn {
    cursor: pointer;
  }

  .buttons-form {
    display: flex;
    justify-content: space-between;
  }

  .service-btn-input {
    display: none;
  }

  .buttons-form .fadeup {
    transform: scale(1);
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .buttons-form .fadeup:hover,
  .fadeup:focus {
    transform: scale(1.2);
  }

  .inactive {
    opacity: 0.2;
  }

  .inactive:hover,
  .inactive:focus {
    opacity: 1;
  }

  .icon img {
    width: 50px;
    margin-bottom: 1rem;
  }

  @media (min-width: 425px) {
    .icon img {
      width: 100px;
      margin-bottom: 1.2rem;
    }
  }

  @media (min-width: 1024px) {
    .icon img {
      width: 140px;
      margin-bottom: 1.3rem;
    }
  }

  .service-description {
    width: 100%;
  }

  .service-description .fadeup-2 {
    width: 100%;
  }

  @media (min-width: 1025px) {
    .service-description {
      width: 100%;
    }

    .service-description .fadeup-2 {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .service-description .row {
    text-align: left;
    display: flex;
    justify-content: space-around;
  }
`

const SectionList = ({ subtitle, listItems }) => (
  <div className="listing">
    <div className="title">{subtitle}</div>
    <ul className="body list-unstyled">
      {listItems.map(item => (
        <li key={shortid.generate()}>{item}</li>
      ))}
    </ul>
  </div>
)

export default function ServiceWeOffer() {
  const [chosenItem, setChosenItem] = useState("")

  const handleChange = ({ target: { value } }) => {
    if (value === chosenItem) {
      return setChosenItem("")
    }
    setChosenItem(value)
  }
  const { t } = useTranslation()

  const dataMapping = [
    {
      key: "website",
      image: iconWebsite,
      sectionInfo: {
        title: t("websiteSecTitle"),
        description: t("websiteSecDescription"),
        subSections: [
          {
            subtitle: t("discover"),
            sectionList: [
              t("discoverListItem1"),
              t("discoverListItem2"),
              t("discoverListItem3"),
              t("discoverListItem4"),
            ],
          },
          {
            subtitle: t("design"),
            sectionList: [
              t("designListItem1"),
              t("designListItem2"),
              t("designListItem3"),
              t("designListItem4"),
              t("designListItem5"),
              t("designListItem6"),
              t("designListItem7"),
              t("designListItem8"),
            ],
          },
          {
            subtitle: t("development"),
            sectionList: [
              t("developmentListItem1"),
              t("developmentListItem2"),
              t("developmentListItem3"),
              t("developmentListItem4"),
              t("developmentListItem5"),
              t("developmentListItem6"),
              t("developmentListItem7"),
              t("developmentListItem8"),
              t("developmentListItem9"),
              t("developmentListItem10"),
              t("developmentListItem11"),
              t("developmentListItem12"),
            ],
          },
          {
            subtitle: t("other"),
            sectionList: [
              t("otherListItem1"),
              t("otherListItem2"),
              t("otherListItem3"),
              t("otherListItem4"),
            ],
          },
        ],
      },
    },
    {
      key: "eCommerce",
      image: iconECommerce,
      sectionInfo: {
        title: t("eCommerceSecTitle"),
        description: t("eCommerceSecDescription"),
        subSections: [
          {
            subtitle: t("discover"),
            sectionList: [
              t("discoverListItem1"),
              t("discoverListItem2"),
              t("discoverListItem3"),
              t("discoverListItem4"),
            ],
          },
          {
            subtitle: t("design"),
            sectionList: [
              t("designListItem1"),
              t("designListItem2"),
              t("designListItem3"),
              t("designListItem4"),
              t("designListItem5"),
              t("designListItem6"),
              t("designListItem7"),
              t("designListItem8"),
            ],
          },
          {
            subtitle: t("development"),
            sectionList: [
              t("developmentListItem13"),
              t("developmentListItem14"),
              t("developmentListItem15"),
              t("developmentListItem2"),
              t("developmentListItem4"),
              t("developmentListItem5"),
              t("developmentListItem6"),
              t("developmentListItem7"),
              t("developmentListItem9"),
              t("developmentListItem10"),
              t("developmentListItem11"),
              t("developmentListItem12"),
            ],
          },
          {
            subtitle: t("other"),
            sectionList: [
              t("otherListItem1"),
              t("otherListItem2"),
              t("otherListItem3"),
              t("otherListItem4"),
            ],
          },
        ],
      },
    },
    {
      key: "mobileApp",
      image: iconMobileApp,
      sectionInfo: {
        title: t("mobileAppSecTitle"),
        description: t("mobileAppSecDescription"),
        subSections: [
          {
            subtitle: t("discover"),
            sectionList: [
              t("discoverListItem1"),
              t("discoverListItem2"),
              t("discoverListItem3"),
              t("discoverListItem4"),
            ],
          },
          {
            subtitle: t("design"),
            sectionList: [
              t("designListItem9"),
              t("designListItem10"),
              t("designListItem2"),
              t("designListItem3"),
              t("designListItem5"),
              t("designListItem6"),
              t("designListItem7"),
            ],
          },
          {
            subtitle: t("development"),
            sectionList: [
              t("developmentListItem16"),
              t("developmentListItem17"),
              t("developmentListItem2"),
              t("developmentListItem4"),
              t("developmentListItem5"),
              t("developmentListItem6"),
              t("developmentListItem18"),
              t("developmentListItem9"),
              t("developmentListItem10"),
              t("developmentListItem11"),
              t("developmentListItem12"),
            ],
          },
          {
            subtitle: t("other"),
            sectionList: [
              t("otherListItem1"),
              t("otherListItem5"),
              t("otherListItem3"),
              t("otherListItem4"),
            ],
          },
        ],
      },
    },
    {
      key: "branding",
      image: iconBranding,
      sectionInfo: {
        title: t("brandingSecTitle"),
        description: t("brandingSecDescription"),
        subSections: [
          {
            subtitle: t("discover"),
            sectionList: [
              t("discoverListItem1"),
              t("discoverListItem2"),
              t("discoverListItem5"),
              t("discoverListItem6"),
            ],
          },
          {
            subtitle: t("design"),
            sectionList: [
              t("designListItem11"),
              t("designListItem12"),
              t("designListItem13"),
              t("designListItem14"),
              t("designListItem15"),
              t("designListItem16"),
              t("designListItem17"),
              t("designListItem18"),
              t("designListItem19"),
              t("designListItem20"),
              t("designListItem7"),
              t("designListItem21"),
            ],
          },
          {
            subtitle: t("other"),
            sectionList: [
              t("otherListItem1"),
              t("otherListItem6"),
              t("otherListItem7"),
            ],
          },
        ],
      },
    },
    {
      key: "graphic",
      image: iconGraphic,
      sectionInfo: {
        title: t("graphicSecTitle"),
        description: t("graphicSecDescription"),
        subSections: [
          {
            subtitle: t("discover"),
            sectionList: [
              t("discoverListItem1"),
              t("discoverListItem2"),
              t("discoverListItem6"),
              t("discoverListItem3"),
            ],
          },
          {
            subtitle: t("design"),
            sectionList: [
              t("designListItem18"),
              t("designListItem22"),
              t("designListItem23"),
              t("designListItem24"),
              t("designListItem25"),
              t("designListItem26"),
              t("designListItem27"),
              t("designListItem28"),
              t("designListItem19"),
              t("designListItem20"),
              t("designListItem7"),
              t("designListItem21"),
            ],
          },
          {
            subtitle: t("other"),
            sectionList: [
              t("otherListItem1"),
              t("otherListItem6"),
              t("otherListItem7"),
            ],
          },
        ],
      },
    },
  ]
  return (
    <MainContentWrap>
      <ServiceWeOfferWrap>
        <div id="service">
          <div className="container-big">
            <div className="wrapper-middle">
              <h1 className="fadeup">
                {t("weOfferSecTitle1")} <br />
                {t("weOfferSecTitle2")}
              </h1>
              <div className="fadeup fadeup-2 sub">
                {t("weOfferSecDescription")}
              </div>
            </div>

            <div id="service-buttons" className="hidden-xs">
              <form className="buttons-form">
                {dataMapping.map(({ key, image }) => {
                  const altText = `icon service ${key}`
                  const active =
                    chosenItem === key
                      ? "active"
                      : chosenItem === ""
                      ? ""
                      : "inactive"
                  return (
                    <label
                      key={shortid.generate().toString()}
                      className="service-btn"
                    >
                      <input
                        type="button"
                        value={key}
                        onClick={handleChange}
                        className="service-btn-input icon-square2"
                      />
                      <div className={`fadeup ${active}`}>
                        <div className="icon">
                          <img src={image} alt={altText} />
                        </div>
                        <div className="text">{t(key)}</div>
                      </div>
                    </label>
                  )
                })}
              </form>
            </div>
          </div>
        </div>

        <div className="service-description col-sm-8 col-sm-offset-1 col-md-9 col-md-offset-0">
          {dataMapping.map(({ key, sectionInfo }) => {
            if (chosenItem !== key) {
              return <></>
            }
            const { title, description, subSections } = sectionInfo
            return (
              <div className="tab">
                <h3 className="fadeup">{title}</h3>
                <div className="fadeup fadeup-2 sub">{description}</div>
                <div className="row">
                  {subSections.map(({ title, subtitle, sectionList }) => (
                    <SectionList
                      key={shortid.generate()}
                      subtitle={subtitle}
                      listItems={sectionList}
                    />
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </ServiceWeOfferWrap>
    </MainContentWrap>
  )
}
