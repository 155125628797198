import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { graphql } from "gatsby"
import SEO from "../components/seo"
import ColorThemeWrapper from "../components/Wrappers/ColorThemeWrapper"
import ServiceWeOffer from "../components/Services/ServiceWeOffer"
import ServiceHowWeWork from "../components/Services/ServiceHowWeWork"
import ServicePartners from "../components/Services/ServicePartners"

const Service = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t("seoTitle")} />
      <ColorThemeWrapper
        wrap1={<ServiceWeOffer />}
        wrap2={<ServiceHowWeWork />}
        wrap3={<ServicePartners />}
      />
    </>
  )
}

export default Service

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "service"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
