import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import MainContentWrap from "../Wrappers/MainContentWrap"

const ServicePartnersWrap = styled.div`
  #partners-list .row {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
  }

  @media (min-width: 600px) {
    #partners-list .row {
      justify-content: space-between;
    }
  }
`

export default function ServicePartners() {
  const { t } = useTranslation()
  return (
    <MainContentWrap>
      <ServicePartnersWrap>
        <div className="wrapper-center">
          <h2 className="fadeup">{t("servicePartnersSecTitle")}</h2>
          <div className="fadeup fadeup-2 sub">
            {t("servicePartnersSecDescription")}
          </div>
        </div>
        <div id="partners-list">
          <div className="row hidden-xs">
            <div className="col-sm-3 fadeup fadeup-3">
              <div className="listing">
                <div className="title">SOCIAL</div>
                <ul className="body list-unstyled">
                  <li>Research &amp; Analysis</li>
                  <li>Social Strategy</li>
                  <li>Campaign Development</li>
                  <li>Content Creation</li>
                  <li>Account Management</li>
                  <li>Post Management</li>
                  <li>Community Management</li>
                  <li>Influencer Engagement</li>
                  <li>Blogger / Media Relations</li>
                  <li>Social Media Marketing (SMM)</li>
                  <li>Analytics &amp; Tracking</li>
                  <li>Monitoring &amp; Reporting</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3 fadeup fadeup-4">
              <div className="listing">
                <div className="title">CONTENT</div>
                <ul className="body list-unstyled">
                  <li>Copywriting</li>
                  <li>Editorial</li>
                  <li>Storytelling</li>
                  <li>Scripting</li>
                  <li>Photography</li>
                  <li>Video Production</li>
                  <li>Animation</li>
                  <li>Motion Graphics</li>
                  <li>Music &amp; Sound</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3 fadeup fadeup-5">
              <div className="listing">
                <div className="title">MARKETING</div>
                <ul className="body list-unstyled">
                  <li>Research &amp; Analysis</li>
                  <li>Marketing Strategy</li>
                  <li>Campaign Development</li>
                  <li>Content Creation</li>
                  <li>Integrated Marketing</li>
                  <li>Search Engine Optimization (SEO)</li>
                  <li>Search Engine Marketing (SEM)</li>
                  <li>Online Display Advertising</li>
                  <li>Remarketing &amp; Retargeting</li>
                  <li>Email Marketing</li>
                  <li>Media Planning &amp; Buying</li>
                  <li>Analytics &amp; Tracking</li>
                  <li>Monitoring &amp; Reporting</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3 fadeup fadeup-6">
              <div className="listing">
                <div className="title">OTHERS</div>
                <ul className="body list-unstyled">
                  <li>Website Hosting</li>
                  <li>Website Domain</li>
                  <li>Server Management</li>
                  <li>Digital &amp; Offset Printing</li>
                  <li>PR &amp; Communication</li>
                  <li>Environmental Installations</li>
                  <li>Events &amp; Exhibition</li>
                  <li>Game Development</li>
                  <li>Customer Care &amp; Support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ServicePartnersWrap>
    </MainContentWrap>
  )
}
